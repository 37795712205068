import React, { Component } from "react";
import PropTypes from "prop-types";
import Recaptcha from "react-recaptcha";
import * as commonActions from "../../actions/commonActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import objectAssign from "object-assign";

class CaptchaInfo extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    captchaInfo: PropTypes.object,
    commonActions: PropTypes.object
  };

  setResponseToken = e => {
    this.props.commonActions.updateCaptchaInfo(
      objectAssign({}, this.props.captchaInfo, { responseToken: e })
    );
  };

  resetResponseToken = e => {
    this.props.commonActions.updateCaptchaInfo(
      objectAssign({}, this.props.captchaInfo, { responseToken: "" })
    );
  };

  render() {
    const { appSettings } = this.props;
    return (
      <div align="center">
        <Recaptcha
          sitekey={appSettings.reCaptchaSiteKey}
          render="explicit"
          onloadCallback={this.resetResponseToken}
          verifyCallback={this.setResponseToken}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    captchaInfo: state.captchaInfo,
    appSettings: state.appSettings
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CaptchaInfo);
