import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as commonActions from "../../actions/commonActions";
import NumericInput from "@cx/ui/NumericInput";
import TextArea from "@cx/ui/TextArea";
import objectAssign from "object-assign";

class ProductInfo extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    commonActions: PropTypes.object,
    productInfo: PropTypes.object
  };
  changeApplicableProducts = e => {
    this.props.commonActions.updateProductInfo(
      objectAssign({}, this.props.productInfo, {
        applicableProducts: e.target.value
      })
    );
  };
  changeServicedDealers = e => {
    this.props.commonActions.updateProductInfo(
      objectAssign({}, this.props.productInfo, {
        servicedDealers: e.target.value
      })
    );
  };
  changeDataExposed = e => {
    this.props.commonActions.updateProductInfo(
      objectAssign({}, this.props.productInfo, { dataExposed: e.target.value })
    );
  };
  changeDataConsumers = e => {
    this.props.commonActions.updateProductInfo(
      objectAssign({}, this.props.productInfo, {
        dataConsumers: e.target.value
      })
    );
  };
  changeComments = e => {
    this.props.commonActions.updateProductInfo(
      objectAssign({}, this.props.productInfo, { comments: e.target.value })
    );
  };

  render() {
    const { appSettings, productInfo } = this.props;
    return (
      <div>
        <h3>Product Information</h3>
        <hr />
        {appSettings.isContractedDeveloper ? (
          <TextArea
            htmlId="ApplicableProducts"
            label="Names of applicable products sold to dealers"
            name="textAreaApplicableProducts"
            onChange={this.changeApplicableProducts}
            value={productInfo.applicableProducts}
            required
            rows={10}
          />
        ) : (
          <div />
        )}
        {appSettings.isContractedDeveloper ? (
          <NumericInput
            htmlId="NumberOfDealers"
            label="Number of dealers serviced by these products"
            name="numberOfDealers"
            maxLength={10}
            onChange={this.changeServicedDealers}
            required
            value={productInfo.servicedDealers}
          />
        ) : (
          <div />
        )}
        <TextArea
          htmlId="DataExposed"
          label="How will data be exposed?"
          name="textAreaDataExposed"
          onChange={this.changeDataExposed}
          value={productInfo.dataExposed}
          required
        />
        <TextArea
          htmlId="DataConsumers"
          label="Who are the consumers of this data?"
          name="textAreaDataConsumers"
          onChange={this.changeDataConsumers}
          value={productInfo.dataConsumers}
          required
        />
        <TextArea
          htmlId="Notes"
          label="Additional Notes / Comments"
          name="textAreaNotes"
          onChange={this.changeComments}
          value={productInfo.comments}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productInfo: state.productInfo,
    appSettings: state.appSettings
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInfo);
