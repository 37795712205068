import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Button from "@cx/ui/Button";
import * as utility from "../../util/utility";
import * as commonActions from "../../actions/commonActions";

class SubmitButton extends Component {
  static propTypes = {
    appSettings: PropTypes.object,
    captchaInfo: PropTypes.object,
    commonActions: PropTypes.object,
    companyInfo: PropTypes.object,
    contactInfo: PropTypes.object,
    productInfo: PropTypes.object
  };

  submitClick = () => {
    window.postMessage({ task: "scroll_top" }, "*");
    this.props.commonActions.submitRegistrationForm();
  };

  render() {
    const {
      companyInfo,
      contactInfo,
      productInfo,
      captchaInfo,
      appSettings
    } = this.props;
    if (
      utility.hasRequiredCompanyInfo(companyInfo) &&
      utility.hasRequiredContactInfo(contactInfo) &&
      utility.hasRequiredProductInfo(
        productInfo,
        appSettings.isContractedDeveloper
      ) &&
      utility.hasRequiredCaptchaToken(captchaInfo)
    ) {
      return (
        <div align="center">
          <Button
            htmlId="submit"
            buttonStyle="primary"
            onClick={this.submitClick}
          >
            Submit
          </Button>
        </div>
      );
    } else {
      return (
        <div align="center">
          <small>
            Please, complete all the required <small className="ex">(*)</small>{" "}
            fields and the captcha for the submit button to activate.
          </small>
          <div>
            <Button htmlId="submit" buttonStyle="primary" disabled={true}>
              Submit
            </Button>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    contactInfo: state.contactInfo,
    companyInfo: state.companyInfo,
    productInfo: state.productInfo,
    captchaInfo: state.captchaInfo,
    appSettings: state.appSettings
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitButton);
