import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as commonActions from "../../actions/commonActions";
import TextInput from "@cx/ui/TextInput";
import PhoneInput from "@cx/ui/PhoneInput";
import EmailInput from "@cx/ui/EmailInput";
import objectAssign from "object-assign";

class ContactInfo extends Component {
  static propTypes = {
    commonActions: PropTypes.object,
    contactInfo: PropTypes.object
  };

  changeFirstName = e => {
    this.props.commonActions.updateContactInfo(
      objectAssign({}, this.props.contactInfo, { firstName: e.target.value })
    );
  };
  changeLastName = e => {
    this.props.commonActions.updateContactInfo(
      objectAssign({}, this.props.contactInfo, { lastName: e.target.value })
    );
  };
  changeTitle = e => {
    this.props.commonActions.updateContactInfo(
      objectAssign({}, this.props.contactInfo, { title: e.target.value })
    );
  };
  changePhoneNumber = e => {
    this.props.commonActions.updateContactInfo(
      objectAssign({}, this.props.contactInfo, { phone: e.target.value })
    );
  };
  changeEmail = e => {
    this.props.commonActions.updateContactInfo(
      objectAssign({}, this.props.contactInfo, { email: e.target.value })
    );
  };
  changeCoxAutoUsername = e => {
    this.props.commonActions.updateContactInfo(
      objectAssign({}, this.props.contactInfo, {
        coxAutoUsername: e.target.value
      })
    );
  };

  render() {
    const { contactInfo } = this.props;
    return (
      <div>
        <h3>Contact Information</h3>
        <hr />
        <TextInput
          htmlId="FirstNameRequired"
          label="First Name"
          name="firstname"
          maxLength={50}
          onChange={this.changeFirstName}
          required
          value={contactInfo.firstName}
        />
        <TextInput
          htmlId="LastNameRequired"
          label="Last Name"
          name="lastname"
          maxLength={50}
          onChange={this.changeLastName}
          required
          value={contactInfo.lastName}
        />
        <TextInput
          htmlId="Title"
          label="Title"
          name="title"
          maxLength={50}
          onChange={this.changeTitle}
          required
          value={contactInfo.title}
        />
        <PhoneInput
          htmlId="PhoneInputRequired"
          label="Phone Number"
          name="phoneNumberRequired"
          onChange={this.changePhoneNumber}
          required
          value={contactInfo.phone}
        />
        <EmailInput
          htmlId="EmailInputRequired"
          label="Email"
          name="required-emailInput"
          maxLength={100}
          onChange={this.changeEmail}
          required
          value={contactInfo.email}
        />
        <p>
          Upon VinSolutions approval, client credentials with access to test
          dealerships will be sent to the email address provided.
        </p>
        <TextInput
          htmlId="CoxDevUsername"
          label="Cox Auto Developer Username(s)"
          name="coxdevusername"
          maxLength={50}
          onChange={this.changeCoxAutoUsername}
          required
          value={contactInfo.coxAutoUsername}
        />
        <p>
          Need a Cox Automotive Developer Network account?{" "}
          <a
            href="http://developer.coxautoinc.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Register now!
          </a>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contactInfo: state.contactInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactInfo);
