export function hasValue(object) {
  return object !== undefined && object !== null && object !== "";
}

export function hasRequiredContactInfo(contact) {
  return (
    hasValue(contact.firstName) &&
    hasValue(contact.lastName) &&
    hasValue(contact.phone) &&
    hasValue(contact.title) &&
    hasValue(contact.email) &&
    hasValue(contact.coxAutoUsername)
  );
}

export function hasRequiredProductInfo(product, isContractedDeveloper) {
  if (isContractedDeveloper) {
    return (
      hasValue(product.applicableProducts) &&
      hasValue(product.servicedDealers) &&
      hasValue(product.dataExposed) &&
      hasValue(product.dataConsumers)
    );
  } else {
    return hasValue(product.dataExposed) && hasValue(product.dataConsumers);
  }
}

export function hasRequiredCompanyInfo(company) {
  return (
    hasValue(company.companyType) &&
    hasValue(company.existingPartner) &&
    hasValue(company.companyName) &&
    hasValue(company.streetAddress) &&
    hasValue(company.city) &&
    hasValue(company.state) &&
    hasValue(company.zip)
  );
}

export function hasRequiredCaptchaToken(captcha) {
  return hasValue(captcha.responseToken);
}
