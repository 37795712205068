/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@cx/ui/Grid";
import Col from "@cx/ui/Col";
import Row from "@cx/ui/Row";
import ContactInfo from "../registration/ContactInfo";
import CompanyInfo from "../registration/CompanyInfo";
import ProductInfo from "../registration/ProductInfo";
import CaptchaInfo from "../registration/CaptchaInfo";
import TermsAndConditions from "../registration/TermsAndConditions";
import SubmitButton from "../registration/SubmitButton";
import LoadingSpinner from "@cx/ui/LoadingSpinner";
import Alert from "react-bootstrap/lib/Alert";
import PropTypes from "prop-types";

class HomePage extends Component {
  static propTypes = {
    appSettings: PropTypes.object
  };

  render() {
    const { appSettings } = this.props;
    if (appSettings.loadingSpinner) {
      return <LoadingSpinner htmlId="LoadingSpinnerExample" />;
    }

    if (appSettings.registrationSuccess) {
      return (
        <Grid fluid>
          <Row className={"alert-dialog"}>
            <Col sm={6} md={6} lg={6} lgOffset={3}>
              <Alert bsStyle="success">
                <p>
                  <strong>
                    Thank you for your application! We will contact you soon if
                    we have any questions or issues.
                  </strong>
                </p>
              </Alert>
            </Col>
          </Row>
        </Grid>
      );
    }

    if (appSettings.registrationFailed) {
      return (
        <Grid fluid>
          <Row className={"alert-dialog"}>
            <Col sm={6} md={6} lg={6} lgOffset={3}>
              <Alert bsStyle="danger">
                <p>
                  <strong>
                    Something went wrong. Please resubmit your application.
                  </strong>
                </p>
              </Alert>
            </Col>
          </Row>
        </Grid>
      );
    }

    return (
      <Grid fluid>
        <Row>
          <Col sm={12} md={12} lg={10} lgOffset={1}>
            <h2>VinSolutions Partner Program Application</h2>
            <p>
              Members of the{" "}
              <a
                href="http://developer.coxautoinc.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cox Automotive Developer Network
              </a>{" "}
              can submit an application to become a certified partner using this
              form.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={6} lg={5} lgOffset={1}>
            <ContactInfo />
            <CompanyInfo />
          </Col>
          <Col sm={6} md={6} lg={5}>
            <ProductInfo />
            <CaptchaInfo />
            <SubmitButton />
            <br />
            <TermsAndConditions />
          </Col>
        </Row>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    appSettings: state.appSettings
  };
}

export default connect(mapStateToProps)(HomePage);
