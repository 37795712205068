import React from "react";

function TermsAndConditions() {
  return (
    <div>
      <div>
        <small>
          By clicking the "Submit" button, you certify that you have read and
          agree to the TIBCO Mashery{" "}
          <a
            href="https://www.tibco.com/company/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service{" "}
          </a>
          and{" "}
          <a
            href="https://www.tibco.com/company/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </small>
      </div>
      <br />
      <div>
        <small>
          By submitting this form, you are requesting access to APIs and may
          receive security credentials that allow access to APIs owned by
          VinSolutions, Inc. You must keep all security credential information
          secure and may not share credentials with any third-party. Access to
          APIs or any application(s) is for testing purposes only and should not
          be used for production or contain any personally identifiable
          information. You are solely responsible for the development and
          support of applications and neither VinSolutions nor Cox Automotive
          have any responsibility for the content, development, support, or
          operation of the submitter’s application(s).
        </small>
      </div>
      <br />
      <div>
        <small>
          Furthermore, you acknowledge and agree that receiving access to the
          Mashery testing environment does not constitute a business
          relationship with VinSolutions Inc., Cox Automotive Inc., or any of
          its customers. Use of the VinSolutions or Cox Automotive branding,
          images or trademark is not permitted without the express written
          consent of Cox Automotive. These terms are subject to change.
        </small>
      </div>
    </div>
  );
}
export default TermsAndConditions;
