import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as appSettingsActions from "../../actions/appSettingsActions";
import RadioButtonList from "@cx/ui/RadioButtonList";
import TextInput from "@cx/ui/TextInput";
import objectAssign from "object-assign";

class CompanyInfo extends Component {
  static propTypes = {
    appSettingsActions: PropTypes.object,
    commonActions: PropTypes.object,
    companyInfo: PropTypes.object
  };

  changeCompanyType = e => {
    this.props.appSettingsActions.setContractedDeveloper(
      e.target.value === "Contracted-Developer"
    );
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, { companyType: e.target.value })
    );
  };
  changeExistingPartner = e => {
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, {
        existingPartner: e.target.value
      })
    );
  };
  changeCompanyName = e => {
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, { companyName: e.target.value })
    );
  };
  changeStreetAddress = e => {
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, {
        streetAddress: e.target.value
      })
    );
  };
  changeCity = e => {
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, { city: e.target.value })
    );
  };
  changeState = e => {
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, { state: e.target.value })
    );
  };
  changeZip = e => {
    this.props.commonActions.updateCompanyInfo(
      objectAssign({}, this.props.companyInfo, { zip: e.target.value })
    );
  };

  render() {
    const { companyInfo } = this.props;
    return (
      <div>
        <h3>Company Information</h3>
        <hr />
        <p>
          Are you a third party developer? Or, are you an employee at a
          dealership that uses VinSolutions?
        </p>
        <RadioButtonList
          required
          htmlId="companyTypeRadioButtonListInline"
          name="companyType"
          label="Company Type"
          onChange={this.changeCompanyType}
          value={companyInfo.companyType}
          options={[
            { value: "Contracted-Developer", label: "Contracted Developer" },
            {
              value: "VinSolutions-Customer",
              label: "VinSolutions Customer / Dealership"
            }
          ]}
        />
        <RadioButtonList
          inline
          required
          htmlId="existingPartnerRadioButtonListInline"
          name="existingPartner"
          label="Existing Partner?"
          onChange={this.changeExistingPartner}
          value={companyInfo.existingPartner}
          options={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" }
          ]}
        />
        <TextInput
          htmlId="CompanyNameRequired"
          label="Company Name"
          name="companyname"
          maxLength={50}
          onChange={this.changeCompanyName}
          required
          value={companyInfo.companyName}
        />
        <TextInput
          htmlId="StreetAddressRequired"
          label="Street Address"
          name="streetaddress"
          maxLength={50}
          onChange={this.changeStreetAddress}
          required
          value={companyInfo.streetAddress}
        />
        <TextInput
          htmlId="CityRequired"
          label="City"
          name="city"
          maxLength={50}
          onChange={this.changeCity}
          required
          value={companyInfo.city}
        />
        <TextInput
          htmlId="StateProvinceRequired"
          label="State/Province"
          name="StateProvince"
          maxLength={50}
          onChange={this.changeState}
          required
          value={companyInfo.state}
        />
        <TextInput
          htmlId="ZipPostalCodeRequired"
          label="Zip/PostalCode"
          name="zippostalcode"
          maxLength={50}
          onChange={this.changeZip}
          required
          value={companyInfo.zip}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyInfo: state.companyInfo
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    appSettingsActions: bindActionCreators(appSettingsActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyInfo);
